<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title is-size-3">Dashboard</p>
      <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns has-text-centered">
          <div class="column">
            <div class="box">
              <p class="is-size-4">Total Encrypt</p>
              <p class="is-size-3">{{ generalStats.totalEncrypt }}</p>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <p class="is-size-4">Total Decrypt</p>
              <p class="is-size-3">{{ generalStats.totalDecrypt }}</p>
            </div>
          </div>
        </div>
        <template v-if="isDataLoaded">
          <MonthlyStatisticsChart
            title="Monthly Logs"
            :monthlyLogs="monthlyLogs"
          />
          <CryptoTypeStatisticsChart
            title="Types of Crypto"
            :cryptoTypeStats="cryptoTypeStats"
          />
          <CryptoGeneralStatisticsChart :cryptoStats="cryptoStats" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import AdminService from '@/services/Admin'
import MonthlyStatisticsChart from '@/components/AdminComponents/Chart/MonthlyStatisticsChart.vue'
import CryptoTypeStatisticsChart from '@/components/AdminComponents/Chart/CryptoTypeStatisticsChart.vue'
import CryptoGeneralStatisticsChart from '@/components/AdminComponents/Index/CryptoGeneralStatisticsChart.vue'

export default defineComponent({
  components: {
    MonthlyStatisticsChart,
    CryptoTypeStatisticsChart,
    CryptoGeneralStatisticsChart,
  },
  setup() {
    const cryptoStatisticsData = ref()
    const cryptoTypeStats = ref()
    const cryptoStats = ref()
    const monthlyLogs = ref()
    const isDataLoaded = ref(false)
    const generalStats = computed(() => {
      const stats = {
        totalEncrypt: 0,
        totalDecrypt: 0,
      }
      if (isDataLoaded.value) {
        cryptoTypeStats.value.forEach((data: any) => {
          stats.totalEncrypt += data.encrypt
          stats.totalDecrypt += data.decrypt
        })
      }
      return stats
    })
    const loadGeneralData = async () => {
      const data = await AdminService.loadGeneralData()
      cryptoStatisticsData.value = data.cryptoStatisticsData
      cryptoTypeStats.value = data.cryptoTypeStats
      cryptoStats.value = data.cryptoStats
    }
    const loadLogs = async () => {
      const logs = await AdminService.getMonthlyLog()
      monthlyLogs.value = logs
    }
    const loadData = async () => {
      await loadGeneralData()
      await loadLogs()
      isDataLoaded.value = true
    }
    loadData()
    return {
      cryptoStatisticsData,
      cryptoTypeStats,
      cryptoStats,
      generalStats,
      monthlyLogs,
      isDataLoaded,
    }
  },
})
</script>
