
import { computed, defineComponent, ref } from 'vue'
import AdminService from '@/services/Admin'
import MonthlyStatisticsChart from '@/components/AdminComponents/Chart/MonthlyStatisticsChart.vue'
import CryptoTypeStatisticsChart from '@/components/AdminComponents/Chart/CryptoTypeStatisticsChart.vue'
import CryptoGeneralStatisticsChart from '@/components/AdminComponents/Index/CryptoGeneralStatisticsChart.vue'

export default defineComponent({
  components: {
    MonthlyStatisticsChart,
    CryptoTypeStatisticsChart,
    CryptoGeneralStatisticsChart,
  },
  setup() {
    const cryptoStatisticsData = ref()
    const cryptoTypeStats = ref()
    const cryptoStats = ref()
    const monthlyLogs = ref()
    const isDataLoaded = ref(false)
    const generalStats = computed(() => {
      const stats = {
        totalEncrypt: 0,
        totalDecrypt: 0,
      }
      if (isDataLoaded.value) {
        cryptoTypeStats.value.forEach((data: any) => {
          stats.totalEncrypt += data.encrypt
          stats.totalDecrypt += data.decrypt
        })
      }
      return stats
    })
    const loadGeneralData = async () => {
      const data = await AdminService.loadGeneralData()
      cryptoStatisticsData.value = data.cryptoStatisticsData
      cryptoTypeStats.value = data.cryptoTypeStats
      cryptoStats.value = data.cryptoStats
    }
    const loadLogs = async () => {
      const logs = await AdminService.getMonthlyLog()
      monthlyLogs.value = logs
    }
    const loadData = async () => {
      await loadGeneralData()
      await loadLogs()
      isDataLoaded.value = true
    }
    loadData()
    return {
      cryptoStatisticsData,
      cryptoTypeStats,
      cryptoStats,
      generalStats,
      monthlyLogs,
      isDataLoaded,
    }
  },
})
