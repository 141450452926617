
import { defineComponent } from 'vue'
import Chart from '@/components/Chart.vue'

export default defineComponent({
  props: {
    cryptoStats: {
      type: Array,
      required: true,
    },
  },
  components: { Chart },
  setup(props) {
    const getCryptoStatsProperty = (property: string) =>
      props.cryptoStats.map((stats: any) => stats[property])
    const labels = getCryptoStatsProperty('name')
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Encrypt',
          backgroundColor: '#4F4789',
          data: getCryptoStatsProperty('encrypt'),
        },
        {
          label: 'Decrypt',
          backgroundColor: '#119DA4',
          data: getCryptoStatsProperty('decrypt'),
        },
      ],
    }
    const options = {
      plugins: {
        title: {
          display: true,
          text: 'Crypto Algorithms',
          font: {
            size: 18,
          },
        },
      },
    }
    return { data, options }
  },
})
